@import "../../Styles/Variables";

.player-wrapper {
  background-color: $black;
  position: relative;
  padding-bottom: 56.25%;
  margin: 2em 0;
  width: 100%;
  max-height: 80vh;
}
// .react-player {
//   background-color: $black;
//   position: relative;
//   padding-bottom: 56.25%;
//   margin: 0 auto;
//   height: 0;
//   width: 100%;
// }

.player-iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: $black;

  @include respond-to("xlarge") {
    margin: 0 auto;
  }
  @include respond-to("large") {
    height: 100%;
    margin: 0 auto;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    height: 100%;
  }

  /* Portrait*/
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 50%;
    height: 100%;
  }
}
