$white: #fefde9;
$black: #688a5a;
$purple: #c0e3a3;
$yellow: #f1f8d6;
$orange: #ffc573;
$pink: #f9c7bb;

.black {
  color: $black;
}
.white {
  color: $white;
}

.bg-black {
  background-color: $black;
}
.bg-white {
  background-color: $white;
}
