@import "../../Styles/Variables";

.header-inner-container {
  padding: 10px;
  background-color: $black;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-spacing: 10px;
  display: grid;
  grid-template-columns: 1fr;
  @include respond-to("large") {
    grid-template-columns: 1fr 1fr;
  }
}

.header-container {
  position: relative;
  padding: 0 5px 5px 5px;
  border: 2px solid $white;
  border-top: none;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  @include respond-to("large") {
    width: 67vw;
    border-left: none;
    top: 7px;
  }
}
.header-logo {
  width: 90%;
}

.header-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.5em;
  @include respond-to("large") {
    font-size: 2.5em;
  }
}

.button-active {
  background-color: $yellow;
  color: $black;
  border-radius: 40px;
  border: 2px solid $black;
  padding: 5px 10px;
  position: relative;
  &:focus {
    outline: none;
  }
  font-size: 0.8em;
  @include respond-to("large") {
    padding: 15px 20px;
  }
}

.button-inactive {
  background-color: $black;
  color: $yellow;
  border-radius: 40px;
  border: 2px solid $yellow;
  padding: 5px 10px;
  position: relative;
  transition: all 0.5s;
  font-size: 0.8em;

  &:hover {
    background-color: $yellow;
    color: $black;
  }
  &:focus {
    outline: none;
  }
  @include respond-to("large") {
    font-size: 1em;
    padding: 15px 20px;
  }
}
.button-border-active {
  position: relative;
  border-radius: 40px;
  border: 2px solid $yellow;
  padding: 2px;
  margin-top: -10px;
  @include respond-to("large") {
    margin-top: -20px;
  }
}

.button-border-inactive {
  position: relative;
  border-radius: 40px;
  border: 2px solid $yellow;
  padding: 2px;
}

.wellness-title {
  color: $black;
  background-color: $yellow;
  font-size: 1.5em;
  @include respond-to("medium") {
    font-size: 3em;
  }
}
