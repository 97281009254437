@import "../../Styles/Variables";

.side-bar-inner {
  height: 100%;
  border-radius: 5px;
  border: 2px solid $black;
  padding: 5px;
  min-height: calc(100vh - 75px);
}

.side-bar-middle {
  background-color: $yellow;
  margin: 5px;
  border-radius: 5px;
  padding: 10px;
}
.side-bar-outer {
  border-radius: 5px;
  padding: 5px;
  border: 2px solid white;
  //   border-left: none;
  @include respond-to("large") {
    height: 100%;
    margin: 0 auto;
    position: fixed;
    top: 5px;
    right: 5px;
    height: calc(100vh - 30px);
    width: calc(33vw - 29px);
  }
}

.sidebar-title {
  font-size: 1.7em;
  font-weight: 500;
  margin: 20px;
  margin-left: 10px;
}

.mixtape-outer {
  background-color: $yellow;
  border-radius: 5px;
  border: 2px solid $black;
  padding: 2px;
}
.mixtape-inner {
  background-color: $yellow;
  border-radius: 5px;
  border: 2px solid $black;
  padding: 2px;
}

.mixtape {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0;
  font-size: 1.4em;
  background-color: $black;
  color: $yellow;
  border-radius: 5px;
  border: 2px solid $black;
  padding: 2px;
}

.sidebar-logo {
  width: 100%;
  margin-top: 50px;
}
.sidebar-bottom {
  font-size: 1.7em;
  font-weight: 500;
  margin: 0;
}
