@import "../../Styles/_Variables.scss";

.grid-container {
  width: 100vw;
  padding: 5px 0 0 0;
  border: 2px solid $white;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  @include respond-to("large") {
    width: 67vw;
    margin-bottom: 7px;
    padding: 5px 5px 0 5px;
    border: 2px solid $white;
    border-bottom: none;
    border-left: none;
  }
}

.grid-inner {
  padding: 10px;
  background-color: $black;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  color: $yellow;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  row-gap: 15px;
  column-gap: 15px;
  @include respond-to("large") {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-logo {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
  }
  @include respond-to("large") {
    img {
      width: 50%;
    }
  }
}
